import {
    createPortal,
    useEffect,
    lazy,
    Suspense,
    useRef,
    createContext,
    useState,
    useContext,
} from '@wordpress/element';
import Shortcode from './Shortcode';
import LoadingSpinner from './components/LoadingSpinner';
import { getExistingCheckout, getRetailerId, scrollToShop } from './util';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import ErrorComponent from './ErrorComponent';
import { storageItemName } from './config';
import * as storage from './browserStorage';
import { ConfigContext } from './index.js';
import { useCartData, useRetailerData } from './hooks/dutchie-queries.js';
import { useCreateCart } from './hooks/dutchie-mutations.js';
import { Helmet } from 'react-helmet';
import Fallback from './Fallback.jsx';

const CartSidebar = lazy(() => import('./components/Cart/CartSidebar'));
const LocationModal = lazy(() =>
    import('./components/LocationSelect/LocationModal')
);

export const RetailerIdContext = createContext(null);
export const ToggleSidebarContext = createContext(null);

const App = ({ shortcodeData, fallback }) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [retailerId, setRetailerId] = useState(null);
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const [fallbackEnabled, setFallbackEnabled] = useState(false);

    const checkout = getExistingCheckout();

    const { retailers } = useContext(ConfigContext);

    // Retailer is only explicitly set via 'headlessproducts' shortcode.
    const retailerShortcode = Object.values(shortcodeData).find(
        (data) => data.shortcode === 'headlessproducts'
    )?.retailerShortcode;

    // Only render the cart sidebar for these shortcodes.
    const renderCartSidebar = Object.values(shortcodeData).find(
        (data) =>
            data.shortcode === 'singleproduct' ||
            data.shortcode === 'headlessproducts' ||
            data.shortcode === 'headlessmenu'
    );

    // If API has only one retailer or not.
    const isSingleRetailer = Array.isArray(retailers) && retailers.length === 1;

    /**
     * Fetch retailer data.
     */
    const { data: retailerData, isFetched: isFetchedRetailerData } =
        useRetailerData(retailerId);

    /**
     * Used to 1) not repeat create cart mutation and 2) not run fetch cart if creating one.
     */
    const cartCreatedRetailerId = useRef(false);

    /**
     * Fetch existing cart data.
     */
    const {
        data: cartData,
        isFetched: isFetchedCartData,
        isFetching: isFetchingCartData,
    } = useCartData(retailerId, Boolean(cartCreatedRetailerId !== retailerId));

    /**
     * Create new cart.
     */
    const handleCreateCart = useCreateCart(retailerId);

    /**
     * Remove existing cart in localStorage if retailerId mismatch.
     */
    if (retailerId && checkout && checkout.retailerId !== retailerId) {
        storage.removeItem(storageItemName);
    }

    /**
     * Navigate to menu immediately if only one shop.
     */
    useEffect(() => {
        if (
            isSingleRetailer &&
            params?.viewName === 'shop' &&
            typeof params.categoryName === 'undefined'
        ) {
            if (location.search) {
                const searchParams = new URLSearchParams(location.search);
                const searchQuery = searchParams.get('search');

                navigate(`/shop/${retailers[0].menuSlug}/${location.search}`, {
                    state: { setFilters: { search: searchQuery } },
                });
                scrollToShop();
            } else {
                navigate(`/shop/${retailers[0].menuSlug}/`, {
                    state: { setFilters: false },
                });
            }
        }
    }, []);

    /**
     * Check if params have changed and set new retailer ID and create new cart.
     */
    useEffect(() => {
        if (retailerData === undefined && !retailerId) {
            const rId = isSingleRetailer
                ? retailers[0].id
                : getRetailerId(retailerShortcode, params, retailers);

            if (rId) {
                setFallbackEnabled(fallback[rId].enabled);
                setRetailerId(rId);
            }
        }

        if (
            Array.isArray(retailers) &&
            retailers.length > 1 &&
            params?.locationSlug
        ) {
            const newRetailerId = getRetailerId(
                retailerShortcode,
                params,
                retailers
            );

            if (
                retailerId !== null &&
                newRetailerId !== false &&
                retailerId !== newRetailerId &&
                !fbEnabled
            ) {
                setFallbackEnabled(fallback[newRetailerId].enabled);
                setRetailerId(newRetailerId);

                handleCreateCart.mutate({
                    retailerId: newRetailerId,
                    orderType: 'PICKUP',
                    pricingType: 'RECREATIONAL',
                });
            }
        }
    }, [params]);

    /**
     * Create cart if new cart hasn't been created.
     */
    useEffect(() => {
        if (retailerId && cartCreatedRetailerId.current !== retailerId) {
            /**
             * Create new cart if no checkout in localStorage and no retailer ID.
             */
            if (
                checkout === false &&
                retailerId &&
                !handleCreateCart.isPending
            ) {
                cartCreatedRetailerId.current = retailerId;
                handleCreateCart.mutate({
                    retailerId,
                    orderType: 'PICKUP',
                    pricingType: 'RECREATIONAL',
                });
            }
        }
    }, [retailerId, isFetchedCartData, isFetchingCartData]);

    // emergency fallback.
    if (fallbackEnabled && retailerId) {
        return (
            <>
                {Object.entries(shortcodeData).map(([rootId, dataset], i) => {
                    let root = document.getElementById(rootId);

                    if (i === 0) {
                        return (
                            <Fallback
                                key={rootId}
                                rootId={rootId}
                                shortcodeData={dataset}
                                script={fallback[retailerId].script}
                            />
                        );
                    } else {
                        return createPortal(
                            <Fallback
                                key={rootId}
                                rootId={rootId}
                                shortcodeData={dataset}
                                script={fallback[retailerId].script}
                            />,
                            root
                        );
                    }
                })}
            </>
        );
    }
    // if there's retailers data but no retailer ID set, it means a location has to be chosen.
    else if (
        Array.isArray(retailers) &&
        !retailerId &&
        retailers.length > 1 &&
        !fallbackEnabled
    ) {
        return createPortal(
            <ErrorBoundary
                showDialog
                fallback={(props) => <ErrorComponent {...props} />}
            >
                <Suspense fallback={<LoadingSpinner />}>
                    <Helmet>
                        <link rel='canonical' href={window.location.href} />
                    </Helmet>
                    <LocationModal
                        retailers={retailers}
                        setRetailerId={setRetailerId}
                    />
                </Suspense>
            </ErrorBoundary>,
            document.body
        );
        // if there's retailers data and a retailer ID set, we're good to load the main app.
    } else if (isFetchedRetailerData && cartData !== null && !fallbackEnabled) {
        return (
            <>
                <Helmet>
                    <link rel='canonical' href={window.location.href} />
                </Helmet>
                <RetailerIdContext.Provider value={retailerId}>
                    <ToggleSidebarContext.Provider
                        value={{ sidebarToggle, setSidebarToggle }}
                    >
                        <ErrorBoundary
                            showDialog
                            fallback={(props) => <ErrorComponent {...props} />}
                        >
                            {Object.entries(shortcodeData).map(
                                ([rootId, dataset], i) => {
                                    let root = document.getElementById(rootId);

                                    if (i === 0) {
                                        return (
                                            <Shortcode
                                                key={rootId}
                                                rootId={rootId}
                                                shortcodeData={dataset}
                                                setRetailerId={setRetailerId}
                                            />
                                        );
                                    } else {
                                        return createPortal(
                                            <Shortcode
                                                key={rootId}
                                                rootId={rootId}
                                                shortcodeData={dataset}
                                                setRetailerId={setRetailerId}
                                            />,
                                            root
                                        );
                                    }
                                }
                            )}
                            {renderCartSidebar &&
                                createPortal(
                                    <ErrorBoundary
                                        showDialog
                                        fallback={(props) => (
                                            <ErrorComponent {...props} />
                                        )}
                                    >
                                        <Suspense fallback={<LoadingSpinner />}>
                                            <CartSidebar />
                                        </Suspense>
                                    </ErrorBoundary>,
                                    document.body
                                )}
                        </ErrorBoundary>
                    </ToggleSidebarContext.Provider>
                </RetailerIdContext.Provider>
            </>
        );
    }
};

export default App;
